import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  IDataLanguage,
  IDataSource,
  IGetStructuredDataParams,
  ILanguage,
  IRootState,
  IStructuredDataContent,
} from "@ax/types";
import { isReqOk } from "@ax/helpers";
import { structuredData } from "@ax/api";
import { AsyncSelect, Button, Loader, Select, TextField } from "@ax/components";
import { useDebounce } from "@ax/hooks";
import { IReferenceState, useReference } from "../Context";
import Item from "./Item";

import * as S from "./style";

const ManualPanel = (props: IProps) => {
  const { onChange, currentSite, hasMaxItems, handleValidation, validators, lang, globalLangs } = props;

  const { state, setState } = useReference();

  const [isLoading, setIsLoading] = useState(false);
  const initForm: IFormState = {
    source: state.sourceTitles[0]?.id,
    lang: lang.id,
    site: state.site || currentSite,
  };
  const [form, setForm] = useState(initForm);
  const [itemSite, setItemSite] = useState<number | null>(state.site || currentSite);
  const debouncedSearch = useDebounce(state.search);

  const langOptions = globalLangs.map((lang) => ({ label: lang.label, value: lang.id.toString() }));

  useEffect(() => {
    let isMounted = true;
    const params: IGetStructuredDataParams = {
      dataID: form.source,
      page: 1,
      itemsPerPage: 50,
      pagination: true,
      deleted: false,
      include_draft: false,
      query: debouncedSearch,
      lang: form.lang,
    };

    const getAndSetItems = async () => {
      if (!isMounted) return;

      setIsLoading(true);
      const siteID = form.site ? form.site : currentSite;
      const response: { status: number; data: { totalItems: number; items: any } } =
        await structuredData.getDataContents(params, siteID);
      if (isReqOk(response?.status)) {
        const { items } = response.data;
        setState((state: IReferenceState) => ({ ...state, items }));
      } else {
        console.log("Error en getAndSetItems");
      }
      setIsLoading(false);
    };
    form.source && getAndSetItems();

    return function cleanup() {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.site, form.source, debouncedSearch, state.mode, form.lang]);

  const handleOnClick = (item: IStructuredDataContent) => {
    let newSelIds: number[];
    let selItems: number[];
    const itemSelectedID = item.dataLanguages?.length
      ? item.dataLanguages.find((dataLang: IDataLanguage) => state.fixed.includes(dataLang.id))?.id
      : state.fixed.includes(item.id)
      ? item.id
      : undefined;

    if (itemSelectedID) {
      newSelIds = state.fixed.filter((a: number) => a !== itemSelectedID);
      selItems = state.selectedItems.filter((b: IStructuredDataContent) => b.id !== itemSelectedID);
      if(selItems.length === 0) {
        setItemSite(form.site);
      }
    } else {
      newSelIds = [...state.fixed, item.id];
      selItems = [...state.selectedItems, item];
      setItemSite(form.site);
    }
    const showSelected = state.showSelected && newSelIds.length === 0 ? false : state.showSelected;
    setState((state: IReferenceState) => ({ ...state, fixed: newSelIds, selectedItems: selItems, showSelected }));
  };

  const handleSelectedClick = () =>
    setState((state: IReferenceState) => ({ ...state, showSelected: !state.showSelected }));

  const handleAdd = () => {
    const { mode, fixed, fullRelations, site, lang } = state;
    const newValue = { mode, fixed, fullRelations, site, lang };
    onChange(newValue);
    handleValidation && handleValidation(state.fixed, validators);
  };

  const textFieldProps = {
    value: state.search,
    onChange: (search: string) => setState((state: IReferenceState) => ({ ...state, search })),
    placeholder: "Search",
    icon: "search",
    iconPosition: "in",
  };

  const buttonText = !state.showSelected ? `view selected (${state.fixed.length})` : "hide selected";

  const showedItems = state.showSelected ? state.selectedItems : state.items;

  const options = state.sourceTitles.map((singleSource: IDataSource) => {
    return { label: singleSource.title, value: singleSource.id };
  });

  const handleSource = (newSource: string) => setForm({ ...form, source: newSource });

  const handleLang = (newLang: string) => setForm({ ...form, lang: parseInt(newLang) });

  const handleSite = (newSite: string | number | null) => {
    (typeof newSite === "number" || newSite === null) && setForm({ ...form, site: newSite });
    const siteID = newSite !== currentSite && newSite !== null ? newSite : undefined;
    setState((state: IReferenceState) => ({ ...state, site: siteID }));
  };

  const hasContentFromOtherSite = state.fixed.length > 0 && form.site !== itemSite && !state.showSelected;

  return (
    <S.Wrapper>
      {state.fixed.length > 0 && (
        <S.ButtonWrapper>
          <Button type="button" buttonStyle="text" onClick={handleSelectedClick}>
            {buttonText}
          </Button>
        </S.ButtonWrapper>
      )}
      {!state.showSelected && (
        <>
          <S.SelectsWrapper>
            {globalLangs.length > 1 && (
              <Select
                name="selectLang"
                options={langOptions}
                onChange={handleLang}
                value={form.lang.toString()}
                type="inline"
                mandatory={true}
              />
            )}
            {currentSite && (
              <AsyncSelect
                name="select"
                entity="sites"
                onChange={handleSite}
                value={form.site}
                type="inline"
                placeholder="Select"
                maxWidth={130}
                mandatory={true}
              />
            )}
            {state.sourceTitles.length > 1 && (
              <Select
                name="selectSource"
                options={options}
                onChange={handleSource}
                value={form.source}
                type="inline"
                mandatory={true}
                maxWidth={70}
              />
            )}
          </S.SelectsWrapper>
          <S.SearchWrapper>
            <TextField {...textFieldProps} />
          </S.SearchWrapper>
        </>
      )}
      {isLoading ? (
        <S.LoadingWrapper>
          <Loader name="circle" />
        </S.LoadingWrapper>
      ) : (
        <S.ListWrapper>
          <S.Order>Most recent</S.Order>
          {state.site && !hasContentFromOtherSite && !state.showSelected && (
            <S.Note>
              You can only select content <strong>from 1 site</strong>.
            </S.Note>
          )}
          {hasContentFromOtherSite && (
            <S.Note>
              Select content from one site at a time. <strong>Remove previous selection</strong> to choose from other
              site.
            </S.Note>
          )}
          <S.ItemList>
            {showedItems &&
              showedItems.map((item: IStructuredDataContent) => {
                const isChecked = state.fixed.includes(item.id);
                const hasVersionInPageLanguage = item.dataLanguages.find(
                  (dataLang: IDataLanguage) => dataLang.language === lang.id && item.language !== lang.id
                );
                const isSelectedOtherLanguage = item.dataLanguages.find(
                  (dataLang: IDataLanguage) => state.fixed.includes(dataLang.id) && dataLang.language !== form.lang
                );
                const source = state.sourceTitles.find((el: IDataSource) => el.id === item.structuredData);
                const disabled = (hasMaxItems && !isChecked) || !!hasVersionInPageLanguage || hasContentFromOtherSite;
                return (
                  <React.Fragment key={`${item.content.title}-${item.id}`}>
                    {source && (
                      <Item
                        isChecked={isChecked || !!isSelectedOtherLanguage}
                        handleOnClick={handleOnClick}
                        item={item}
                        source={source}
                        disabled={disabled}
                        tooltip={hasVersionInPageLanguage ? "Content has version in page language" : ""}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </S.ItemList>
        </S.ListWrapper>
      )}
      <S.ActionsWrapper>
        {state.fixed.length > 0 && (
          <Button type="button" onClick={handleAdd}>
            Add
          </Button>
        )}
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

interface IProps {
  currentSite: number | null;
  onChange: (value: any) => void;
  hasMaxItems: boolean;
  handleValidation?: (value: string, validators?: Record<string, unknown>) => void;
  validators?: Record<string, unknown>;
  lang: { locale: string; id: number };
  globalLangs: ILanguage[];
}

interface IFormState {
  source: string | undefined;
  lang: number;
  site: number | null;
}

const mapStateToProps = (state: IRootState) => ({
  lang: state.app.lang,
  currentSite: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  globalLangs: state.app.globalLangs,
});

export default connect(mapStateToProps)(ManualPanel);
