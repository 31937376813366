import { template } from "./config";
import { IServiceConfig, sendInitialRequest, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_USERS: {
    ...template,
    endpoint: "/users",
    method: "GET",
  },
  GET_SITE_USERS: {
    ...template,
    endpoint: ["/site/", "/users"],
    method: "GET",
  },
  GET_USER: {
    ...template,
    endpoint: "/user/",
    method: "GET",
  },
  UPDATE_USER: {
    ...template,
    endpoint: "/user/",
    method: "PUT",
  },
  UPDATE_PASSWORD: {
    ...template,
    endpoint: ["/user/", "/password/change"],
    method: "PUT",
  },
  CREATE_USER: {
    ...template,
    endpoint: "/user",
    method: "POST",
  },
  DELETE_USER: {
    ...template,
    endpoint: "/user/",
    method: "DELETE",
  },
  DELETE_USER_BULK: {
    ...template,
    endpoint: "/user/bulk",
    method: "DELETE",
  },
  RESEND: {
    ...template,
    endpoint: ["/user/", "/resend"],
    method: "PUT",
  },
};

const getUsers = async (params: any) => {
  const { host, endpoint } = SERVICES.GET_USERS;

  const { query, filterQuery } = params;

  SERVICES.GET_USERS.dynamicUrl = `${host}${endpoint}${filterQuery}`;

  if (query && query.trim() !== "") {
    SERVICES.GET_USERS.dynamicUrl = `${SERVICES.GET_USERS.dynamicUrl}${query}`;
  }

  return sendRequest(SERVICES.GET_USERS);
};

const getSiteUsers = async (params: any, siteID: number) => {
  const { host, endpoint: [prefix, suffix], } = SERVICES.GET_SITE_USERS;

  const { query, filterQuery } = params;

  SERVICES.GET_SITE_USERS.dynamicUrl = `${host}${prefix}${siteID}${suffix}${filterQuery}`;

  if (query && query.trim() !== "") {
    SERVICES.GET_SITE_USERS.dynamicUrl = `${SERVICES.GET_USERS.dynamicUrl}${query}`;
  }

  return sendRequest(SERVICES.GET_SITE_USERS);
};

const getUser = async (id: string | number, token?: string) => {
  const { host, endpoint } = SERVICES.GET_USER;

  SERVICES.GET_USER.dynamicUrl = `${host}${endpoint}${id}`;

  return token ? sendInitialRequest(SERVICES.GET_USER, token) : sendRequest(SERVICES.GET_USER);
};

const updateUser = async (id: number, data: any) => {
  const { host, endpoint } = SERVICES.UPDATE_USER;

  SERVICES.UPDATE_USER.dynamicUrl = `${host}${endpoint}${id}`;

  return sendRequest(SERVICES.UPDATE_USER, data);
};

const updatePassword = async (id: number, data: any) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_PASSWORD;

  SERVICES.UPDATE_PASSWORD.dynamicUrl = `${host}${prefix}${id}${suffix}`;

  return sendRequest(SERVICES.UPDATE_PASSWORD, data);
};

const createUser = async (data: any) => {
  return sendRequest(SERVICES.CREATE_USER, { ...data });
};

const deleteUser = async (userID: number) => {
  const { host, endpoint } = SERVICES.DELETE_USER;

  SERVICES.DELETE_USER.dynamicUrl = `${host}${endpoint}${userID}`;
  return sendRequest(SERVICES.DELETE_USER);
};

const deleteUserBulk = async (userID: number[]) => {
  return sendRequest(SERVICES.DELETE_USER_BULK, { ids: userID });
};

const resendInvitation = async (id: number) => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.RESEND;

  SERVICES.RESEND.dynamicUrl = `${host}${prefix}${id}${suffix}`;

  return sendRequest(SERVICES.RESEND);
};

export default {
  getUsers,
  getSiteUsers,
  getUser,
  updateUser,
  updatePassword,
  createUser,
  deleteUser,
  deleteUserBulk,
  resendInvitation,
};
