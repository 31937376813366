import React, { memo, useEffect, useReducer, useRef } from "react";

import { files } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import { Icon, DragAndDrop } from "@ax/components";
import {
  reducer,
  initialState,
  setDropDepth,
  setInDropZone,
  setIsUploading,
  setUploadSuccess,
  setUploadError,
  MAX_SIZE,
} from "./../store";

import * as S from "./style";

const FileDragAndDrop = (props: IProps) => {
  const { validFormats, addFile } = props;

  const validExtensions = validFormats.map((format) => `.${format}`).join(",");

  const filesInputRef = useRef<any>(null);
  const filesButtonRef = useRef<any>(null);

  useEffect(() => {
    if (filesInputRef.current) {
      filesInputRef.current.addEventListener("change", handleFilesUpload, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesInputRef]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleDragEnter = () => {
    dispatch(setDropDepth(state.dropDepth + 1));
  };

  const handleDragLeave = () => {
    dispatch(setDropDepth(state.dropDepth - 1));
    if (state.dropDepth > 1) return;
    dispatch(setInDropZone(false));
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.dropEffect = "copy";
    dispatch(setInDropZone(true));
  };

  const checkType = (type: string) => {
    for (const i in validFormats) {
      if (type.includes(validFormats[i])) return true;
    }
    return false;
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    const files = Array.from(e.dataTransfer.files);
    e.dataTransfer.clearData();
    await handleUploadFile(files);
    dispatch(setDropDepth(0));
  };

  const handleFilesUpload = (e: any) => {
    const files = Array.from(e.currentTarget.files);
    handleUploadFile(files);
  };

  const handleUploadFile = async (filesArr: Array<any>) => {
    if (filesArr[0]) {
      dispatch(setIsUploading());
      const fileSize = filesArr[0].size / (1024 * 1024);

      if (!checkType(filesArr[0].type)) {
        dispatch(setUploadError("Invalid format"));
      } else if (fileSize > MAX_SIZE) {
        dispatch(setUploadError("File is too big"));
      } else {
        try {
          const form = new FormData();
          form.append("file", filesArr[0]);
          const result = await files.uploadFile(form);
          if (isReqOk(result.status)) {
            dispatch(setUploadSuccess());
            setTimeout(function () {
              addFile(result.data);
              dispatch(setInDropZone(false));
            }, 800);
          }
        } catch (e) {
          dispatch(setUploadError("Error uploading file"));
        }
      }
    }
  };

  const handleTryAgain = () => {
    dispatch(setInDropZone(false));
  };

  const handleFileClick = () => {
    if (filesInputRef) {
      filesInputRef.current.click();
    }
  };

  const errorWrapper = state.errorMsg ? <S.ErrorMsg>{state.errorMsg}</S.ErrorMsg> : null;

  return (
    <S.Wrapper data-testid="file-drag-and-drop-wrapper">
      <S.DragAndDropWrapper
        inDropZone={state.inDropZone}
        uploading={state.isUploading}
        success={state.isSuccess}
        error={state.isError}
      >
        <DragAndDrop
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          validFormats={validFormats}
        >
          <S.StatusWrapper>
            <S.DragStatus>
              <S.DragIcon>
                <Icon name="uploadFile" size="48" />
              </S.DragIcon>
              <S.DragTitle>Drag your file here</S.DragTitle>
              <S.DragSubtitle>or</S.DragSubtitle>
              <S.FilesInput type="file" ref={filesInputRef} multiple accept={validExtensions} />
              <S.FilesButton ref={filesButtonRef} type="button" buttonStyle="line" onClick={handleFileClick}>
                Select files
              </S.FilesButton>
              <S.DragSubtitle>Valid formats: {validFormats.join(", ")}. Max. size: 25MB</S.DragSubtitle>
            </S.DragStatus>
            <S.DragOverStatus>
              <S.DragIcon>
                <Icon name="success" size="48" />
              </S.DragIcon>
              <S.DragTitle>Drop your file</S.DragTitle>
              <S.DragSubtitle>Valid formats: {validFormats.join(", ")}. Max. size: 25MB</S.DragSubtitle>
            </S.DragOverStatus>
          </S.StatusWrapper>
        </DragAndDrop>
      </S.DragAndDropWrapper>
      <S.UploadingWrapper
        inDropZone={state.inDropZone}
        uploading={state.isUploading}
        success={state.isSuccess}
        error={state.isError}
      >
        <S.StatusWrapper>
          <S.UploadingStatus>
            <S.DragIcon>
              <Icon name="uploadFile" size="48" />
            </S.DragIcon>
            <S.DragTitle>Uploading...</S.DragTitle>
          </S.UploadingStatus>
          <S.SuccessStatus>
            <S.DragIcon>
              <Icon name="success" size="48" />
            </S.DragIcon>
            <S.DragTitle>File loaded!</S.DragTitle>
          </S.SuccessStatus>
          <S.ErrorStatus>
            <S.DragIcon>
              <Icon name="alert" size="48" />
            </S.DragIcon>
            <S.DragTitle>Error uploading file</S.DragTitle>
            {errorWrapper}
            <S.StyledButton type="button" buttonStyle="text" onClick={handleTryAgain}>
              TRY AGAIN
            </S.StyledButton>
          </S.ErrorStatus>
        </S.StatusWrapper>
      </S.UploadingWrapper>
    </S.Wrapper>
  );
};

interface IProps {
  validFormats: string[];
  addFile: (file: any) => void;
}

export default memo(FileDragAndDrop);
