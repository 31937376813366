import React from "react";
import { GriddoImage } from "@griddo/core";
import { createCloudinaryUrl, isCloudinary } from "./utils";

const DAM_DEFAULTS = {
  quality: 75,
  crop: "cover",
  loading: "lazy",
  formats: ["webp"],
};

const Image = (props: IImageProps): React.ReactElement => {
  const { url } = props;

  if (isCloudinary(url)) {
    const cloudinaryUrl = createCloudinaryUrl({ props });
    return <img data-testid="image-wrapper" src={cloudinaryUrl} alt="Griddo site thumbnail" />;
  } else {
    return <GriddoImage {...DAM_DEFAULTS} {...props} />;
  }
};

export interface IImageProps {
  width: number;
  height?: number;
  url: string;
  quality?: number;
  crop?: string;
  loading?: string;
  formats?: string[];
}

export default Image;
