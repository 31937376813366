import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { appActions } from "@ax/containers/App";
import { usersActions } from "@ax/containers/Users";
import { Loading, MainWrapper, Modal, Toast } from "@ax/components";
import { IRootState, ISite, ISiteRoles, IUser } from "@ax/types";
import { useModal, useToast } from "@ax/hooks";
import UserForm from "../UserForm";

import * as S from "./style";

const UserEdit = (props: IProps) => {
  const { user, updateUser, isSaving, isLoading, deleteUser, currentUser, setHistoryPush, site, sites } = props;

  const { timezone } = user;
  const initForm = { ...user };
  if (!timezone) initForm.timezone = "Europe/Madrid";
  const isSiteView = !!site;
  const sitesIds = sites.map((site: ISite) => site.id);
  const usersRoute = isSiteView ? "/sites/users" : "users";
  const [form, setForm] = useState<IUser>(initForm);
  const { isOpen, toggleModal } = useModal();
  const { isOpen: isOpenAdvise, toggleModal: toggleAdviseModal } = useModal(false);
  const { isVisible, toggleToast, setIsVisible } = useToast();

  useEffect(() => {
    const { timezone } = user;
    const initForm = { ...user };
    if (!timezone) initForm.timezone = "Europe/Madrid";
    setForm(initForm);
  }, [user]);

  const handleSave = async () => {
    if(isOpenAdvise){
      toggleAdviseModal();
    }
    const updated = form.id ? await updateUser(form.id, form, false) : false;
    if (updated) {
      toggleToast();
    }
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : "Update User",
    disabled: isSaving,
    action: form.roles.length === 0 && !form.isSuperAdmin ? toggleAdviseModal : handleSave,
  };

  const handleDelete = () => {
    user.id &&
      deleteUser(user.id).then((deleted: boolean) => {
        if (deleted) {
          setForm({ ...form, id: null });
          setHistoryPush(usersRoute);
        }
      });
  };

  const isSameUser = currentUser && currentUser.id === user.id;
  const isDeleteDisabled = isSiteView && user.isSuperAdmin;

  const rightLineButtonProps = !isSameUser && !isDeleteDisabled
    ? {
        label: isSiteView ? "Remove user from this site" : "Delete User",
        action: toggleModal,
      }
    : undefined;

  const getUpdatedSites = () => {
    const hasAll = form.roles.find((siteRole: ISiteRoles) => siteRole.siteId === "all");
    if (hasAll) {
      const oldRoleSites = form.roles.filter((siteRole: ISiteRoles) => siteRole.siteId !== "all");
      const roleSites = sitesIds
        .filter((siteId: number) => siteId !== site?.id)
        .map((siteId: number) => {
          return { siteId, roles: hasAll.roles };
        });
      return [...oldRoleSites, ...roleSites];
    } else {
      return form.roles.filter((siteRole: ISiteRoles) => siteRole.siteId !== site?.id);
    }
  };

  const removeUserFromSite = async () => {
    if (!user.id) return;
    const updatedSites = getUpdatedSites();
    const formWithUpdatedSites = { ...form, roles: updatedSites };

    const updated = await updateUser(user.id, formWithUpdatedSites, true);
    if (updated) {
      setHistoryPush(usersRoute);
    }
  };

  const mainDeleteAction = {
    title: isSiteView ? "Remove user" : "Delete User",
    onClick: isSiteView ? removeUserFromSite : handleDelete,
  };

  const secondaryDeleteAction = { title: "Cancel", onClick: toggleModal };

  const toastProps = {
    setIsVisible,
    message: "User updated",
  };

  const mainModalAction = {
    title: "Edit User",
    onClick: handleSave,
  };

  const secondaryModalAction = { title: "Cancel", onClick: toggleAdviseModal };

  if (isLoading) return <Loading />;

  return (
    <MainWrapper
      title="Edit User"
      rightButton={rightButtonProps}
      backLink={true}
      rightLineButton={isSiteView ? rightLineButtonProps : undefined}
    >
      <UserForm form={form} setForm={setForm} user={user} isSiteView={isSiteView} site={site} />
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        title={isSiteView ? "Remove user from this site?" : "Delete User?"}
        secondaryAction={secondaryDeleteAction}
        mainAction={mainDeleteAction}
      >
        {isOpen ? (
          <S.ModalContent>
            {isSiteView ? (
              <p>
                Are you sure you want to remove <strong>{user.email}</strong> from this site? If you remove it, this
                user will no longer have access to this site but it will still be able to log in.
              </p>
            ) : (
              <p>
                Are you sure you want to delete <strong>{user.email}</strong>? If you delete it, this user will no
                longer be able to log in.
              </p>
            )}
            <p>
              This action <strong>cannot be undone</strong>.
            </p>
          </S.ModalContent>
        ) : null}
      </Modal>
      <Modal
        isOpen={isOpenAdvise}
        hide={toggleAdviseModal}
        title="Edit User?"
        secondaryAction={secondaryModalAction}
        mainAction={mainModalAction}
        size="S"
      >
        <S.ModalContent>
          Before editing an user, <strong>select the user's permissions and site access</strong>. If you proceed without selecting
          any permissions, the user <strong>won't have access to anything or view any content</strong>.
        </S.ModalContent>
      </Modal>
      {isVisible && <Toast {...toastProps} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  user: state.users.userForm,
  site: state.sites.currentSiteInfo,
  sites: state.sites.sites,
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  currentUser: state.users.currentUser,
});

interface IDispatchProps {
  setHistoryPush(path: string): void;
  updateUser(id: number, data: any, isProfile: boolean): Promise<boolean>;
  deleteUser(id: number): Promise<boolean>;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  updateUser: usersActions.updateUser,
  deleteUser: usersActions.deleteUser,
};

interface IProfileProps {
  user: IUser;
  site: ISite | null;
  isSaving: boolean;
  isLoading: boolean;
  currentUser: IUser | null;
  sites: ISite[];
}

type IProps = IProfileProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
