import styled from "styled-components";

const Wrapper = styled.div<{ isEditor: boolean }>`
  width: 453px;
  height: ${(p) => `calc(${p.theme.spacing.m} * 2)`};
  background: ${(p) => p.theme.color.uiMainMenuBackground};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  position: fixed;
  z-index: 1100;
  bottom: ${(p) => p.theme.spacing.m};
  left: ${(p) => (p.isEditor ? `calc(${p.theme.spacing.m} * 1)` : `calc(${p.theme.spacing.m} * 4)`)};
  padding-left: ${(p) => p.theme.spacing.m};
  padding-right: ${(p) => p.theme.spacing.s};
  animation-name: transition;
  animation-duration: 5s;
  opacity: 0;

  &.close-animation {
    animation-name: transition-inversed;
    animation-duration: 1s;
  }

  @keyframes transition {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    20% {
      opacity: 1;
      transform: translateX(0);
    }
    80% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  @keyframes transition-inversed {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
`;

const Text = styled.div`
   color: #fff;
`;

const ButtonWrapper = styled.div`
   color: #fff;
`;

const Buttons = styled.div`
   color: #fff;
   display: flex;
   align-items: center;
`;

export { Wrapper, Text, ButtonWrapper, Buttons }