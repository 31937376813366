import React, { useState } from "react";

import { structuredData } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import { IGetStructuredDataParams, IDataSource, ISite, IStructuredData } from "@ax/types";
import { Button, CheckField, FieldsBehavior, FloatingMenu, Icon, IconAction, SearchField, Tag } from "@ax/components";

import * as S from "./style";

const AutoItem = (props: IProps) => {
  const { source, canDelete, filter, handleDelete, addFilter, currentSite, structuredDataSite, siteID } = props;

  const initState: IState = {
    selected: "",
    options: [],
    filter,
    isOpen: false,
    filteredOptions: [],
  };

  const [state, setState] = useState(initState);

  const removeItem = (item: string) => {
    handleDelete(item);
  };

  const MoreInfoButton = () => (
    <S.IconActionWrapper>
      <IconAction icon="more" />
    </S.IconActionWrapper>
  );

  const actionMenuItem = (item: any, source: string) => {
    const handleClick = () => item.action(source);
    return (
      <S.ActionItem key={item.icon} onClick={handleClick} data-testid="auto-item-action-item">
        <Icon name={item.icon} />
        <S.ActionText>{item.label}</S.ActionText>
      </S.ActionItem>
    );
  };

  const editMenu = {
    options: [
      {
        label: "delete",
        icon: "delete",
        action: removeItem,
      },
    ],
  };

  const ActionMenu = (props: any) => {
    const { source } = props;
    return (
      <FloatingMenu Button={MoreInfoButton}>
        <S.ActionMenu>{editMenu.options.map((item: any, i: number) => actionMenuItem(item, source))}</S.ActionMenu>
      </FloatingMenu>
    );
  };

  const getIsGlobal = (id: string): boolean => {
    const data = structuredDataSite && structuredDataSite.find((elem: any) => elem.id === id);
    return data && data.local ? false : true;
  };

  const getCategoryContent = async (newValue: string) => {
    const params: IGetStructuredDataParams = {
      dataID: newValue,
      page: 1,
      itemsPerPage: 50,
      pagination: false,
      deleted: false,
      include_draft: false,
      order: "alpha-asc",
    };

    const site = getIsGlobal(newValue) ? null : siteID;

    try {
      const result = await structuredData.getDataContents(params, site);
      if (isReqOk(result.status)) {
        setState({ ...state, selected: newValue, options: result.data.items, filteredOptions: result.data.items });
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  const handleSelectOnChange = (newValue: string | null) => {
    if (newValue) {
      getCategoryContent(newValue);
    } else {
      setState({ ...state, selected: "", options: [], filteredOptions: [] });
    }
  };

  const handleCheckChange = (checkValue: any) => {
    if (checkValue.isChecked) {
      const newFilter: any = state.options.filter((f: any) => checkValue.value === f.id);
      setState({
        ...state,
        filter: [...state.filter, { id: newFilter[0].id, label: newFilter[0].content.title, source: source.id }],
      });
    } else {
      const newFilters = state.filter.filter((e) => e.id !== checkValue.value);
      setState({ ...state, filter: newFilters });
    }
  };

  const handleButtonClick = () => {
    addFilter(state.filter, source.id);
    setState({ ...state, isOpen: false });
  };

  const isChecked = (id: number) => {
    const ids = state.filter.map((a) => a.id);
    return ids.includes(id);
  };

  const toggleFilters = () => {
    setState({ ...state, isOpen: !state.isOpen });
  };

  const handleDeleteTag = (id: number) => {
    const newFilters = state.filter.filter((e) => e.id !== id);
    setState({ ...state, filter: newFilters });
    addFilter(newFilters, source.id);
  };

  const handleSearchChange = (query: string) => {
    const filteredOptions = state.options.filter(
      (option) => option.content.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    setState({ ...state, filteredOptions });
  };

  return (
    <S.TypeContainer key={source.id}>
      <S.TypeWrapper>
        <S.TypeLabel>Type</S.TypeLabel>
        <S.TypeName>{source.title}</S.TypeName>
        {canDelete && <ActionMenu source={source.id} />}
        <S.IconWrapper>
          <IconAction icon="filter" onClick={toggleFilters} />
        </S.IconWrapper>
      </S.TypeWrapper>
      <S.FiltersWrapper>
        {filter &&
          filter.map((e: any) => {
            const handleClick = () => {
              handleDeleteTag(e.id);
            };
            return <Tag key={e.label} text={e.label} onDeleteAction={handleClick} />;
          })}
      </S.FiltersWrapper>
      <S.ActionsFilterWrapper isOpen={state.isOpen}>
        <FieldsBehavior
          title="Create filter by"
          name="categorySelect"
          fieldType="AsyncSelect"
          value={state.selected}
          entity="categories"
          onChange={handleSelectOnChange}
          entityId={source.id}
          site={currentSite}
        />
        {state.options.length > 0 && (
          <S.SearchWrapper>
            <SearchField onChange={handleSearchChange} searchOnEnter={false} size="XS" />
          </S.SearchWrapper>
        )}
        <S.ChecksWrapper>
          {state.filteredOptions &&
            state.filteredOptions.map((option: any) => (
              <CheckField
                key={option.content.title}
                title={option.content.title}
                name={option.id}
                value={option.id}
                checked={isChecked(option.id)}
                onChange={handleCheckChange}
              />
            ))}
        </S.ChecksWrapper>
        {state.options.length > 0 && (
          <S.ButtonWrapper>
            <Button type="button" buttonStyle="line" onClick={handleButtonClick}>
              Add filter
            </Button>
          </S.ButtonWrapper>
        )}
      </S.ActionsFilterWrapper>
    </S.TypeContainer>
  );
};

interface IState {
  selected: string;
  options: any[];
  filter: any[];
  isOpen: boolean;
  filteredOptions: any[];
}

interface IProps {
  source: IDataSource;
  canDelete: boolean;
  filter: number[];
  currentSite: ISite | null;
  structuredDataSite: IStructuredData[];
  handleDelete: (value: string) => void;
  addFilter: (value: any[], source: string) => void;
  siteID?: number | null;
}

export default AutoItem;
