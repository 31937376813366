import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { structuredData } from "@ax/api";
import { IDataSource, IRootState, IStructuredDataContent } from "@ax/types";
import { isReqOk } from "@ax/helpers";
import Item from "./Item";

import { IReferenceState, useReference } from "../Context";
import * as S from "./style";

const ItemList = (props: IProps) => {
  const { items, currentSite, handleListDelete, site } = props;

  const { state, setState, setReorderElements } = useReference();
  const { fixed, selectedItems, sourceTitles, fullRelations } = state;

  useEffect(() => {
    const params = {
      mode: "manual",
      fixed: items,
      fullRelations,
      site,
    };

    const getItems = async () => {
      const siteID = currentSite ? currentSite : "global";
      const response = await structuredData.getDistributorContent(siteID, params);
      if (isReqOk(response?.status)) {
        setState((state: IReferenceState) => ({ ...state, selectedItems: response.data }));
        if (fixed.length !== response.data.length) {
          const itemsID = response.data.map((item: IStructuredDataContent) => item.id);
          setState((state: IReferenceState) => ({ ...state, fixed: itemsID }));
        }
      } else {
        console.log("Error en getItems");
      }
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleDelete = (item: IStructuredDataContent) => {
    const langItem = item.dataLanguages?.length
      ? item.dataLanguages.find((lang) => fixed.includes(lang.id))
      : fixed.includes(item.id)
      ? item
      : null;

    if (langItem) {
      const newSelIds = fixed.filter((id: number) => id !== langItem.id);
      const selItems = selectedItems.filter((item: IStructuredDataContent) => item.id !== langItem.id);
      setState((state: IReferenceState) => ({ ...state, fixed: newSelIds, selectedItems: selItems }));
      handleListDelete(newSelIds);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const item = selectedItems.find((elem: IStructuredDataContent) => elem.id === parseInt(result.draggableId));
    setReorderElements(item, result.destination.index);
  };

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((item: IStructuredDataContent, index: number) => {
      const source = sourceTitles.find((el: IDataSource) => el.id === item.structuredData);
      return (
        <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
          {(provided) => (
            <Item
              item={item}
              handleDelete={handleDelete}
              listLength={selectedItems.length}
              source={source}
              innerRef={provided.innerRef}
              provided={provided}
            />
          )}
        </Draggable>
      );
    });
  });

  return (
    <S.ItemList>
      {selectedItems && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="referenceList">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} data-testid="droppable">
                <ComponentList components={selectedItems} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </S.ItemList>
  );
};

interface IProps {
  items: number[];
  currentSite: number | null;
  handleListDelete(value: string[]): void;
  site?: number;
}

const mapStateToProps = (state: IRootState) => ({
  currentSite: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
});

export default connect(mapStateToProps)(ItemList);
