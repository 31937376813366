import React from "react";

import { IconAction } from "@ax/components";

import * as S from "./style";

const ArrayFieldInline = (props: IProps): JSX.Element => {
  const { fields, item, index, onChange, handleDelete } = props;

  const deleteItem = () => handleDelete(index);

  const getFields = (fields: any[]): any[] => {
    return fields.map((field: any) => {
      const key = field.props.objKey;

      const handleChange = (newValue: any) => onChange({ [key]: newValue });

      const innerFields = field.props.innerFields ? getFields(field.props.innerFields) : undefined;

      return {
        ...field,
        props: { ...field.props, value: item[key], objKey: key, onChange: handleChange, innerFields },
        key,
      };
    });
  };

  return (
    <S.Wrapper data-testid="arrayFieldInline">
      <S.Content>
        <S.IconWrapper>
          <IconAction icon="delete" onClick={deleteItem} size="s" />
        </S.IconWrapper>
        {getFields(fields)}
      </S.Content>
    </S.Wrapper>
  );
};

interface IProps {
  fields: any[];
  item: Record<string, unknown>;
  index: number;
  onChange: (value: Record<string, unknown>) => void;
  handleDelete: (index: number) => void;
}

export default ArrayFieldInline;
