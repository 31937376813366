import { useSite } from "./useSite";
import {
	getDamIdFromDamUrl,
	getDomainFromDamUrl,
	getGriddoDamURIWithParams,
	removeUnit,
} from "../functions/image-utils";
import {
	ImageCropType,
	ImageDecoding,
	ImageFormats,
	ImageLoading,
	ImagePosition,
	ImageTransform,
} from "../types/core";

const MIME_TYPES = {
	jpg: "image/jpeg",
	jpeg: "image/jpeg",
	gif: "image/gif",
	svg: "image/svg+xml",
	png: "image/png",
	avif: "image/avif",
	webp: "image/webp",
};

const internalDefaults: ImageConfigExperimental = {
	quality: 75,
	crop: "cover",
	loading: "lazy",
	decoding: "auto",
	formats: ["webp"],
};

/**
 * `useGriddoImage()`
 */
function useGriddoImageExp({
	url,
	...props
}: UseGriddoImagePropsExperimental): UseGriddoImageReturnExperimental {
	if (!url) {
		return {};
	}

	const { griddoDamDefaults } = useSite();

	const domain = getDomainFromDamUrl(url);
	const damId = getDamIdFromDamUrl(url);

	// This object contains every aspect of the image configuration
	const imageConfig: ImageConfigExperimental = {
		// defaults props from griddo-core
		...internalDefaults,
		// defaults props from instance through site context
		...griddoDamDefaults,
		// props from component
		...props,
		// domain
		domain,
		// HARDCORDED values
		format: "jpeg",
	};

	const RATIO =
		removeCSSUnits(props?.width || "1x") /
		removeCSSUnits(props?.height || "1px");

	// Create the content of the srcSet <img> attribute using the widths prop.
	// if widhts is undefined or empty array, then use the width prop and return
	// a simple url without the "w".
	const srcSet =
		(Array.isArray(imageConfig.widths) && imageConfig.widths.length) ||
		imageConfig.widths !== undefined
			? imageConfig.widths?.map((width) => {
					const griddoDamURL = getGriddoDamURIWithParams({
						damId: damId,
						imageConfig: imageConfig,
						width: width,
						height: imageConfig.height
							? `${Math.round(removeCSSUnits(width) / RATIO)}px`
							: undefined,
					});

					// empty string for the "w" value if not width
					const withWProp = width ? `${removeUnit(width)}w` : "";
					return imageConfig.width ? `${griddoDamURL} ${withWProp}`.trim() : "";
			  })
			: [
					getGriddoDamURIWithParams({
						damId: damId,
						imageConfig: imageConfig,
						width: imageConfig.width,
						height: imageConfig.height
							? `${Math.round(
									removeCSSUnits(imageConfig.width || "1px") / RATIO
							  )}px`
							: undefined,
					}),
			  ];

	// Create a kind of srcSet for background images (removing the final size xxxw)
	const srcSetURL = srcSet?.map((entry) => entry?.split(" ")[0]);

	// Old browsers src fallback (in case they doesn't support srcSet)
	const src = `${getGriddoDamURIWithParams({
		damId,
		imageConfig,
	})}`;

	// MIMEType
	const type = imageConfig?.format
		? MIME_TYPES[imageConfig?.format]
		: MIME_TYPES["jpeg"];

	return {
		type,
		srcSet,
		srcSetURL,
		src,
		webpFallback: generateImageChunk({ srcSet, srcSetURL, format: "jpeg" }),
		jpeg: generateImageChunk({ srcSet, srcSetURL, format: "jpeg" }),
		webp: generateImageChunk({ srcSet, srcSetURL, format: "webp" }),
		avif: generateImageChunk({ srcSet, srcSetURL, format: "avif" }),
		png: generateImageChunk({ srcSet, srcSetURL, format: "png" }),
		gif: generateImageChunk({ srcSet, srcSetURL, format: "gif" }),
		svg: generateImageChunk({ srcSet, srcSetURL, format: "svg" }),
	};
}

function generateImageChunk({
	srcSet,
	srcSetURL,
	format,
}: GenerateImageChunkPropsExperimental): ImageChunkExperimental {
	return {
		type: MIME_TYPES[format] as MIMETypesExperimental,
		srcSet: srcSet?.map((url) => url?.replace(/f\/\w+/, `f/${format}`)),
		srcSetURL: srcSetURL?.map((url) => url?.replace(/f\/\w+/, `f/${format}`)),
	};
}

export interface GenerateImageChunkPropsExperimental {
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	format: ImageFormats;
}

export interface UseGriddoImagePropsExperimental
	extends Omit<ImageConfigExperimental, "formats"> {
	url?: string;
}

export interface ImageConfigExperimental {
	blurCSSTransition?: string;
	blurSize?: string;
	crop?: ImageCropType;
	decoding?: ImageDecoding;
	domain?: string;
	format?: ImageFormats;
	formats?: Array<ImageFormats>;
	height?: string;
	loading?: ImageLoading;
	position?: ImagePosition;
	quality?: number;
	transforms?: ImageTransform;
	width?: string;
	sizes?: string;
	widths?: Array<string>;
	ratio?: number;
}

export type MIMETypesExperimental =
	| "image/avif"
	| "image/gif"
	| "image/jpeg"
	| "image/png"
	| "image/svg+xml"
	| "image/webp";

export interface ImageChunkExperimental {
	type: MIMETypesExperimental;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
}

export interface UseGriddoImageReturnExperimental {
	type?: string;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	src?: string;
	sizes?: string;
	webpFallback?: ImageChunkExperimental;
	jpg?: ImageChunkExperimental;
	jpeg?: ImageChunkExperimental;
	webp?: ImageChunkExperimental;
	avif?: ImageChunkExperimental;
	png?: ImageChunkExperimental;
	gif?: ImageChunkExperimental;
	svg?: ImageChunkExperimental;
}

function removeCSSUnits(value: string) {
	const match = value.match(/^-?\d*\.?\d+/);
	if (match) {
		return parseFloat(match[0]);
	}
	return 0;
}

export { removeCSSUnits, useGriddoImageExp };
