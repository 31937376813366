import styled from "styled-components";

const getWidth = (size: string | undefined) => {
  switch (size) {
    case "M":
      return "640px";
    case "L":
      return "896px";
    case "XL":
      return "1088px";
    default:
      return "512px";
  }
};

const getHeight = (size: string | undefined) => {
  switch (size) {
    case "M":
      return "384px";
    case "L":
      return "512px";
    case "XL":
      return "640px";
    default:
      return "320px";
  }
};

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1250;
  width: 100vw;
  height: 100vh;
  background: ${(p) => p.theme.color.overlay};
  body.modal-open:not(&) {
    overflow: hidden;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1250;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

export const Modal = styled.div<{ size: string | undefined }>`
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  background: ${(p) => p.theme.color.uiBackground01};
  position: relative;
  margin: auto;
  border-radius: 4px;
  width: ${(p) => getWidth(p.size)};
  height: ${(p) => getHeight(p.size)};
  box-shadow: ${(p) => p.theme.shadow.shadowXL};
  top: 50%;
  transform: translateY(-50%);
`;

export const ModalHeader = styled.div`
  background: ${(p) => p.theme.color.uiBarBackground};
  display: flex;
  padding: ${(p) => p.theme.spacing.s};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

export const ModalContent = styled.div<{ overflow: string | undefined }>`
  position: relative;
  flex-grow: 1;
  overflow: ${(p) => p.overflow};
`;

export const Title = styled.div`
  ${(p) => p.theme.textStyle.headingM};
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 0 auto;
`;

export const ModalFooter = styled.div`
  background: ${(p) => p.theme.color.uiBackground01};
  display: flex;
  justify-content: flex-end;
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  button:not(:first-child) {
    margin-left: ${(p) => p.theme.spacing.s};
  }
  border-radius: 0 0 4px 4px;
`;
