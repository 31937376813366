import { IImageProps } from "./index";

// The string to split up the cloudinary url
const CLOUDINARY_SEPARATOR = "image/upload";
// The string to know if the url is a cloudinary url
const CLOUDINARY_HEAD_URL = "https://res.cloudinary.com";

function isCloudinary(url: string): boolean {
  // TODO: Write a better check, probabily parsing the API props from a UrlField
  return url.startsWith(CLOUDINARY_HEAD_URL);
}

function createCloudinaryUrl({ props, retina = true }: ICreateCloudinaryUrl): string {
  // Split the cloudinary url to be able to insert the concat params between them
  const [head, tail] = props.url.split(CLOUDINARY_SEPARATOR);

  // Concat props in a cloudinary url format
  // w_100 for width: 100, etc..
  // * 2 = full retina
  const cloudinaryParms = concatCloudinaryParams({
    w: props.width * (retina ? 2 : 1),
    h: props.height && props.height * (retina ? 2 : 1),
    q: props.quality,
    c: "fill",
    f: "jpg",
  });

  return `${head}${cloudinaryParms}${tail}`;
}

function concatCloudinaryParams(params: ICloudinaryParams) {
  const cloudinaryParms = Object.keys(params)
    .map((key) => (params[key] ? `${key}_${params[key]}` : ""))
    .join(",");

  // This split cleans up the string url with extra commas (,)
  // w_100,,q_10  -->  w_100,q_10 ->
  return cloudinaryParms.split(",").filter(Boolean).join(",");
}

interface ICloudinaryParams {
  w: number;
  h?: number;
  q?: number;
  c: "fill" | "fit";
  f: "webp" | "jpg";
  [key: string]: unknown;
}

interface ICreateCloudinaryUrl {
  props: IImageProps;
  retina?: boolean;
}

export { createCloudinaryUrl, isCloudinary };
