const SET_DROP_DEPTH = "SET_DROP_DEPTH";
const SET_IN_DROP_ZONE = "SET_IN_DROP_ZONE";
const SET_IS_UPLOADING = "SET_IS_UPLOADING";
const SET_UPLOAD_SUCCESS = "SET_UPLOAD_SUCCESS";
const SET_UPLOAD_ERROR = "SET_UPLOAD_ERROR";
export const MAX_SIZE = 25; // MB

export const reducer = (prevState: IGalleryStore, action: any): IGalleryStore => {
  switch (action.type) {
    case SET_DROP_DEPTH:
      return { ...prevState, dropDepth: action.payload.dropDepth };
    case SET_IN_DROP_ZONE:
      return { ...prevState, inDropZone: action.payload.inDropZone, isUploading: false, isError: false, isSuccess: false, errorMsg: "" };
    case SET_IS_UPLOADING:
      return { ...prevState, inDropZone: false, isUploading: true };
    case SET_UPLOAD_SUCCESS:
      return { ...prevState, isUploading: false, isSuccess: true };
    case SET_UPLOAD_ERROR:
      return { ...prevState, inDropZone: false, isUploading: false, isError: true, errorMsg: action.payload.msg };
    default:
      return prevState;
  }
};

export function setDropDepth(dropDepth: number) {
  return { type: SET_DROP_DEPTH, payload: { dropDepth } };
}

export function setInDropZone(inDropZone: boolean) {
  return { type: SET_IN_DROP_ZONE, payload: { inDropZone } };
}

export function setIsUploading() {
  return { type: SET_IS_UPLOADING };
}

export function setUploadSuccess() {
  return { type: SET_UPLOAD_SUCCESS };
}

export function setUploadError(errorMsg: string = "") {
  return { type: SET_UPLOAD_ERROR, payload: { errorMsg } };
}

export const initialState: IGalleryStore = {
  dropDepth: 0,
  inDropZone: false,
  isUploading: false,
  isSuccess: false,
  isError: false,
  errorMsg: "",
};

interface IGalleryStore {
  dropDepth: number;
  inDropZone: boolean;
  isUploading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMsg: string;
}
