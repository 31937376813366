import styled from "styled-components";

export const Tracker = styled.div`
  height: ${p => p.theme.spacing.xxs};
  width: 100%;
  border-radius: 2px;
  background-color: #e6e7f8;
`;

export const ProgressInTracker = styled.div<{ percentage: number }>`
  background-color: ${p => p.theme.color.interactive01};
  border-radius: 2px;
  height: 100%;
  width: ${p => p.percentage}%;
`;
