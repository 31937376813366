import * as React from "react";

import { useGriddoImageExp, useSite } from "../../hooks";
import {
	FetchPriority,
	ImageCropType,
	ImageDecoding,
	ImageLoading,
	ImagePosition,
	ImageTransform,
} from "../../types/core";

// Omit src, srcSet as GriddoImage component will add them and it doesn't need
// to be extends from React types.  Also omit, width and height because
// GriddoImage only accepts strings with 'px' format and the
// React.ComponentPropsWithRef<"img"> an be numbers too.
export interface GriddoImageCommonPropsExperimental
	extends Omit<
		React.ComponentPropsWithRef<"img">,
		"src" | "srcSet" | "width" | "height"
	> {
	crop?: ImageCropType;
	fetchpriority?: FetchPriority;
	position?: ImagePosition;
	url?: string;
	width?: string;
	height?: string;
	fixed?: boolean;
	loading?: ImageLoading;
	decoding?: ImageDecoding;
	quality?: number;
	formats?: Array<"webp" | "avif">;
	transforms?: ImageTransform;
	/**
	 * Array of image widths for the `srcSet` image attribute in pixels
	 * @example
	 * <GriddoImage widths={["640px", "800px", "1280px"]} />
	 */
	widths?: Array<`${string}px`>;
}

export interface GriddoImageSvgExperimental
	extends GriddoImageCommonPropsExperimental {
	// format is used to check if is a GriddoImageSvg image
	format?: "svg";
	responsive?: never;
	quality?: never;
	formats?: never;
	transforms?: never;
}

export interface GriddoImageGifExperimental
	extends GriddoImageCommonPropsExperimental {
	// format is used to check if is a GriddoImageGif image
	format?: "gif";
	responsive?: never;
	quality?: never;
	formats?: never;
	transforms?: never;
}

export interface GriddoImageJpgWebpAvifExperimental
	extends GriddoImageCommonPropsExperimental {
	// format is used to check if is a GriddoImageJpgWebpAvif image
	/** Format is set automatically based on formats prop */
	format?: never;
}

export type GriddoImagePropsExperimental =
	| GriddoImageJpgWebpAvifExperimental
	| GriddoImageGifExperimental
	| GriddoImageSvgExperimental;

/**
 * EXPERIMENTAL - Renders an image from the Griddo CDN.
 * Also return a ref with the `<picture>` element.
 *
 * [GriddoImageExp Reference](https://www.notion.so/griddoio/GriddoImageExp-fdb4ea84ac044b1884f242ac854d9c1d?pvs=4)
 */
const GriddoImageExp = React.forwardRef<
	HTMLPictureElement,
	GriddoImagePropsExperimental
>(
	(
		{
			width,
			widths,
			height,
			url,
			loading,
			decoding,
			quality,
			crop,
			format,
			formats,
			position,
			transforms,
			sizes,
			...props
		},
		ref
	) => {
		if (!url) {
			console.warn("`<GriddoImage> needs a DAM image `url`}");
			return null;
		}

		if (!width && height) {
			console.warn(
				"`<GriddoImageExp> can't use only height, you have to set `width` or `width` & `height` together."
			);
		}

		if ((widths && !sizes) || (!widths && sizes)) {
			console.warn(
				"`<GriddoImageExp> needs a `sizes` property if the prop `widths` is provided and vice versa."
			);
		}

		const { griddoDamDefaults } = useSite() || {};

		const imageConfig = {
			loading: loading || griddoDamDefaults?.loading,
			decoding: decoding || griddoDamDefaults?.decoding,
			quality: quality || griddoDamDefaults?.quality,
			crop: crop || griddoDamDefaults?.crop,
			format: format,
			formats: formats || griddoDamDefaults?.formats,
			position: position,
			transforms: transforms,
			widths: widths, //|| griddoDamDefaults?.widths,
		};

		// Get data necesary for the image component
		const { src, avif, jpeg, gif, webp, svg } = useGriddoImageExp({
			url: url,
			crop: imageConfig.crop,
			format: imageConfig.format,
			quality: imageConfig.quality,
			position: imageConfig.position,
			transforms: imageConfig.transforms,
			// Width & Height
			width: width,
			height: height,
			// Widhts
			widths: imageConfig.widths,
		});

		const GifImage = () => (
			<img
				data-image="griddo"
				loading={loading || imageConfig.loading}
				decoding={decoding || imageConfig.decoding}
				srcSet={gif?.srcSet?.join(",")}
				sizes={sizes}
				src={gif?.srcSetURL && gif.srcSetURL[0]}
				width={width}
				height={height}
				fetchpriority={props.fetchpriority}
				{...props}
			/>
		);

		const SvgImage = () => (
			<img
				data-image="griddo"
				loading={loading || imageConfig.loading}
				decoding={decoding || imageConfig.decoding}
				src={svg?.srcSetURL && svg.srcSetURL[0]}
				width={width}
				height={height}
				fetchpriority={props.fetchpriority}
				{...props}
			/>
		);

		if (format === "svg") {
			return <SvgImage />;
		}

		if (format === "gif") {
			return <GifImage />;
		}

		return (
			<picture ref={ref}>
				{/* avif */}
				{imageConfig.formats?.includes("avif") && (
					<source
						srcSet={avif?.srcSet?.join(",")}
						sizes={sizes}
						type="image/avif"
					/>
				)}

				{/* webp */}
				{imageConfig.formats?.includes("webp") && (
					<source
						srcSet={webp?.srcSet?.join(",")}
						sizes={sizes}
						type="image/webp"
					/>
				)}

				{/* jpeg */}
				<img
					data-image="griddo"
					loading={loading || imageConfig.loading}
					decoding={decoding || imageConfig.decoding}
					sizes={sizes}
					srcSet={jpeg?.srcSet?.join(",")}
					src={src}
					width={width}
					height={height}
					fetchpriority={props.fetchpriority}
					{...props}
				/>
			</picture>
		);
	}
);

export { GriddoImageExp };
