import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  UPLOAD_FILE: {
    ...template,
    endpoint: "/files",
    method: "POST",
  },
};

const uploadFile = async (data: FormData) => {
  return sendRequest(SERVICES.UPLOAD_FILE, data as any);
};

export default { uploadFile };
